import React from 'react'

import Layout from '../components/layout'

const AboutPage = () => (
  <Layout
    metaDescription="About this website, who is behind it, why does he write it"
    metaKeywords="software, development, javier, casas"
  >
    <h1>About</h1>
    <h2>Javier Casas</h2>
    <p>
      I'm Javier Casas, a software engineer obsessed with quality, specially the
      right amount of quality for the situation. I like Functional Programming,
      CS Theory and just good knowledge about Software Development.
    </p>
    <h2>This Blog</h2>
    <p>
      This is my blog. Here you will find ramblings on Software Engineering,
      Haskell and Advanced Programming in general.
    </p>
  </Layout>
)

export default AboutPage
